<template>
  <svg
    width="140"
    height="28"
    viewBox="0 0 140 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="3.4965" height="27.972" fill="white" />
    <rect y="24.4746" width="27.972" height="3.4965" fill="white" />
    <rect x="6.99301" width="3.4965" height="20.979" fill="white" />
    <rect x="13.986" width="3.4965" height="13.986" fill="white" />
    <rect x="20.979" width="3.4965" height="6.99301" fill="white" />
    <rect x="20.979" y="3.49609" width="6.99301" height="3.4965" fill="white" />
    <rect x="6.99301" y="17.4824" width="20.979" height="3.4965" fill="white" />
    <rect x="13.986" y="10.4902" width="13.986" height="3.4965" fill="white" />
    <path d="M52.3162 23.2165V9.16893H55.8406V23.2165H52.3162Z" fill="white" />
    <path
      d="M51.9134 4.81369C51.9134 3.65565 52.8448 2.67383 54.1036 2.67383C55.3372 2.67383 56.2686 3.65565 56.2686 4.81369C56.2686 6.07243 55.3372 7.05425 54.1036 7.05425C52.8448 7.05425 51.9134 6.07243 51.9134 4.81369Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M118.683 26.0361L115.889 22.8137C114.655 23.3424 113.27 23.6445 111.785 23.6445C106.146 23.6445 101.665 19.4654 101.665 13.4235C101.665 7.38152 106.121 3.17732 111.81 3.17732C117.525 3.17732 121.93 7.45705 121.93 13.4235C121.93 16.4445 120.772 19.0626 118.884 20.9004L118.885 20.9018L118.886 20.9019C119.716 21.8581 120.546 22.8142 121.377 23.7955L118.683 26.0361ZM105.491 13.4235C105.491 17.3759 108.084 20.12 111.835 20.12C112.339 20.12 112.842 19.9941 113.296 19.7927C112.93 19.3522 112.559 18.918 112.188 18.4837L112.188 18.4833C111.816 18.0492 111.445 17.615 111.08 17.1745L113.774 14.934C114.543 15.8572 115.332 16.7805 116.106 17.687L116.442 18.0808C117.475 16.8976 118.079 15.2864 118.079 13.4235C118.079 9.64726 115.662 6.7018 111.835 6.7018C108.059 6.7018 105.491 9.52138 105.491 13.4235Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M124.996 23.2165V3.58012H132.574C133.908 3.58012 139.396 4.13397 139.396 9.899C139.396 13.5997 137.206 15.3116 134.966 15.8906V15.9158C136.602 18.3324 138.238 20.7741 139.874 23.2159L139.875 23.2165H135.645L131.013 16.1927H128.748V23.2165H124.996ZM128.748 12.8696H131.894C133.304 12.8696 135.469 12.341 135.469 9.97453C135.469 7.63327 133.329 6.97872 131.945 6.97872H128.748V12.8696Z"
      fill="white"
    />
    <path
      d="M92.6419 18.7354C92.6419 20.1955 92.6419 23.6445 97.3496 23.6445C98.3314 23.6445 99.2629 23.4934 99.9678 23.1158V19.9438C99.4139 20.2459 98.7594 20.4473 98.0797 20.4473C96.1664 20.4473 96.1664 18.8109 96.1664 17.4766V12.3661H99.9678V9.16893H96.1664V5.19131H92.6419V9.16893H89.8727V12.3661H92.6419V18.7354Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M81.7705 23.6445C77.4908 23.6445 74.5202 20.5983 74.5202 16.2179C74.5202 11.8626 77.4908 8.76614 81.7705 8.76614C86.0502 8.76614 89.0209 11.8626 89.0209 16.2179C89.0209 20.5983 86.0502 23.6445 81.7705 23.6445ZM78.1705 16.2179C78.1705 18.5843 79.6558 20.271 81.7705 20.271C83.8852 20.271 85.3453 18.5843 85.3453 16.2179C85.3453 13.8011 83.8852 12.1396 81.7705 12.1396C79.6558 12.1396 78.1705 13.8263 78.1705 16.2179Z"
      fill="white"
    />
    <path
      d="M59.2983 9.16893V23.2165H62.8227V15.9661C62.8227 13.2976 64.2577 12.1396 65.9192 12.1396C68.0843 12.1396 68.5626 14.0528 68.5626 15.9158V23.2165H72.0871V14.3549C72.0871 10.5284 69.771 8.76614 67.178 8.76614C65.4661 8.76614 63.8549 9.54656 62.8227 11.2333H62.7724V9.16893H59.2983Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.6308 3.58012V23.2165H39.3818V16.2934H43.3091C44.7189 16.2934 50.0308 15.6137 50.0308 9.82348C50.0308 4.13397 44.7944 3.58012 43.2084 3.58012H35.6308ZM42.5287 12.6682H39.3818V7.02907H42.579C43.9636 7.02907 46.1035 7.45704 46.1035 9.82348C46.1035 12.2654 43.9385 12.6682 42.5287 12.6682Z"
      fill="white"
    />
  </svg>
</template>
